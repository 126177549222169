import axios from 'axios';
import api from '@/helpers/api';

const state = {
    items: {},
    titleTemplate: '%s | ZemSkidki.ru',
    delayPopupClose: 500,
    putAsideYes:168,
    putAsideNo:185,
}

// getters
const getters = {
    setRouble() {
        return detectOS() ? 'Р' : '₽';
    }
}

const actions = {
    getList(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_PATH+'/options/',api.axiosConfig()).then(response => {
                if(typeof response.data === 'object' && +response.data.error === 0) {
                    context.commit('setOption', response.data.result);
                    resolve();
                }
            }).catch(function (error) {
                reject();
            });
        });
    },
    saveOption(context, data) {
        return new Promise((resolve, reject) => {
            let params = new FormData();
            for(let name in data.params){
                params.append(name, data.params[name]);
            }
            axios.post(process.env.VUE_APP_API_PATH+'/options/', params, api.axiosConfig()).then(response => {
                if(!response.data.result.allowed) {
                    context.dispatch('popups/open', { name: 'errorModal' }, { root: true });
                }
                else {
                    context.commit('setOneOption', {name: 'giveProject', value: data.params.value});
                }
                resolve();
            }).catch(function (error) {
                reject();
            });
        });
    },
    delTestRequest(context, data) {
        return new Promise((resolve, reject) => {
            axios.delete(process.env.VUE_APP_API_PATH+'/options/',api.axiosConfig({id: data})).then(response => {
                if(typeof response.data === 'object' && +response.data.error === 0) {
                    //context.commit('setOption', response.data.result);
                    resolve();
                }
            }).catch(function (error) {
                reject();
            });
        });
    }
}

const mutations = {
    setOption(state, data) {
        if(typeof data === 'object') {
            state.items = data.items;
        }
    },
    setOneOption(state, data) {
        state.items[data.name] = data.value;
    },
    delTestRequest(state, data) {
        for (let prop in state.items.testData.requests) {
            if(state.items.testData.requests[prop].id == data) {
                state.items.testData.requests.splice(prop, 1);
            }
        }
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

function detectOS() {
    let thisUserAgent = window.navigator.userAgent;
    let OS = thisUserAgent.indexOf("iPhone OS") != -1 || thisUserAgent.indexOf("Windows NT 6.1") != -1 || thisUserAgent.indexOf("iPad") != -1 || (thisUserAgent.indexOf('Macintosh') > -1 && 'ontouchend' in document);

    return OS;
}
