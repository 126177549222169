import axios from 'axios';
import api from '@/helpers/api';

const state = {
    curItemData: {},
    items: {
        restoreStatus: {
            name: 'restoreStatus',
            show: false
        },
        standardMeasure: {
            name: 'standardMeasure',
            show: false
        },
        remoteMeasure: {
            name: 'remoteMeasure',
            show: false
        },
        rejectRequest: {
            name: 'rejectRequest',
            show: false
        },
        rejectRequestNow: {
            name: 'rejectRequestNow',
            show: false
        },
        requestThink: {
            name: 'requestThink',
            show: false
        },
        measureDate: {
            name: 'measureDate',
            show: false
        },
        objectAddr: {
            name: 'objectAddr',
            show: false
        },
        rejectReport: {
            name: 'rejectReport',
            show: false
        },
        customerData: {
            name: 'customerData',
            show: false
        },
        customerCharacter: {
            name: 'customerCharacter',
            show: false
        },
        preApprove: {
            name: 'preApprove',
            show: false
        },
        refuseCustomer: {
            name: 'refuseCustomer',
            show: false
        },
        cancelMeasure: {
            name: 'cancelMeasure',
            show: false
        },
        deferMeasure: {
            name: 'deferMeasure',
            show: false
        },
        scheduleInterview: {
            name: 'scheduleInterview',
            show: false
        },
        approve: {
            name: 'approve',
            show: false
        },
        confirmMeasure: {
            name: 'confirmMeasure',
            show: false
        },
        measureTime: {
            name: 'measureTime',
            show: false
        },
        issueInvoice: {
            name: 'issueInvoice',
            show: false
        },
        acceptCash: {
            name: 'acceptCash',
            show: false
        },
        acceptCard: {
            name: 'acceptCard',
            show: false
        },
        acceptMeasurerMoney: {
            name: 'acceptMeasurerMoney',
            show: false
        },
        resumeMeasure: {
            name: 'resumeMeasure',
            show: false
        },
        measureDetail: {
            name: 'measureDetail',
            show: false
        },
        downloadConcept: {
            name: 'downloadConcept',
            show: false
        },
        log: {
            name: 'log',
            show: false
        },
        refuseReason: {
            name: 'refuseReason',
            show: false
        },
        rejectReason: {
            name: 'rejectReason',
            show: false
        },
        deferReason: {
            name: 'deferReason',
            show: false
        },
        cancelReason: {
            name: 'cancelReason',
            show: false
        },
        acceptConcept: {
            name: 'acceptConcept',
            show: false
        },
        scheduleAgreement: {
            name: 'scheduleAgreement',
            show: false
        },
        pauseConcept: {
            name: 'pauseConcept',
            show: false
        },
        replaceConcept: {
            name: 'replaceConcept',
            show: false
        },
        confirmForCustomer: {
            name: 'confirmForCustomer',
            show: false
        },
        resumeConcept: {
            name: 'resumeConcept',
            show: false
        },
        pauseReason: {
            name: 'pauseReason',
            show: false
        },
        setDemo: {
            name: 'setDemo',
            show: false
        },
        requestConfirm: {
            name: 'requestConfirm',
            show: false
        },
        pauseModel: {
            name: 'pauseModel',
            show: false
        },
        resumeModel: {
            name: 'resumeModel',
            show: false
        },
        modelPauseReason: {
            name: 'modelPauseReason',
            show: false
        },
        drawForReview: {
            name: 'drawForReview',
            show: false
        },
        markDrawVerify: {
            name: 'markDrawVerify',
            show: false
        },
        markReady: {
            name: 'markReady',
            show: false
        },
        requestConceptConfirm: {
            name: 'requestConceptConfirm',
            show: false
        },
		    letCustomerThink: {
            name: 'letCustomerThink',
            show: false
        },
		    sendSms: {
            name: 'sendSms',
            show: false
        },
        makeDecision: {
            name: 'makeDecision',
            show: false
        },
        assignTransfer: {
            name: 'assignTransfer',
            show: false
        },
        giveInstallers: {
            name: 'giveInstallers',
            show: false
        },
        modelRequestReview: {
            name: 'modelRequestReview',
            show: false
        },
        modelSetDemoTime: {
            name: 'modelSetDemoTime',
            show: false
        },
        modelSetPause: {
            name: 'modelSetPause',
            show: false
        },
        modelChecked: {
            name: 'modelChecked',
            show: false
        },
        modelRequestConfirm: {
            name: 'modelRequestConfirm',
            show: false
        },
        modelConfirmAsCustomer: {
            name: 'modelConfirmAsCustomer',
            show: false
        },
        modelDemo: {
            name: 'modelDemo',
            show: false
        },
        errorModal: {
            name: 'errorModal',
            show: false
        },
        errorCustomTextModal: {
            name: 'errorCustomTextModal',
            show: false
        },
        availableDate: {
            name: 'availableDate',
            show: false
        },
        removeExpense: {
            name: 'removeExpense',
            show: false,
        },
        giveSalary: {
            name: 'giveSalary',
            show: false
        },
        givenSalary: {
            name: 'givenSalary',
            show: false
        },
        givenReward: {
            name: 'givenReward',
            show: false
        },
        isNotifyReset: {
            name: 'isNotifyReset',
            show: false
        },
        howPressBtn: {
            name: 'howPressBtn',
            show: false
        },
        refuseRepair: {
            name: 'refuseRepair',
            show: false
        },
        repairThink: {
            name: 'repairThink',
            show: false
        },
        repairAside: {
            name: 'repairAside',
            show: false
        },
        repairAsideMore: {
            name: 'repairAsideMore',
            show: false
        },
		    takeToWork: {
            name: 'takeToWork',
            show: false
        },
        takePrepay: {
            name: 'takePrepay',
            show: false
        },
		    repairFinish: {
            name: 'repairFinish',
            show: false
        },
        repairFinishError: {
            name: 'repairFinishError',
            show: false
        },
        editMasterComment: {
            name: 'editMasterComment',
            show: false
        },
        editMasterCommentPrepay: {
            name: 'editMasterCommentPrepay',
            show: false
        },
		    repairTerminate: {
            name: 'repairTerminate',
            show: false
        },
        acceptReport: {
            name: 'acceptReport',
            show: false
        },
        changeRole: {
                name: 'changeRole',
          show: false
        },
		    refuseHistory: {
            name: 'refuseHistory',
            show: false
        },
		    docsFiles: {
            name: 'docsFiles',
            show: false
        },
        editComment: {
            name: 'editComment',
            show: false
        },
        editCommentApplying: {
            name: 'editCommentApplying',
            show: false
        },
		    giveReward: {
            name: 'giveReward',
            show: false
        },
        changeCommand: {
            name: 'changeCommand',
            show: false
        },
        changeZ: {
            name: 'changeZ',
            show: false
        },
        worker:{
          name: 'worker',
          show: false,
        },
        blockWorker: {
          name: 'blockWorker',
          show: false,
        },
        unBlockWorker: {
          name: 'unBlockWorker',
          show: false,
        },
            userSalary: {
          name: 'userSalary',
          show: false,
        },
            rejectApplying: {
          name: 'rejectApplying',
          show: false,
        },
            newApplyingCreated: {
          name: 'newApplyingCreated',
          show: false,
        },
            sendFormLink: {
          name: 'sendFormLink',
          show: false,
        },
            thinkApplying: {
          name: 'thinkApplying',
          show: false,
        },
        changeLeader: {
            name: 'changeLeader',
            show: false,
        },
        changeCoordinator: {
            name: 'changeCoordinator',
            show: false,
        },
        changeAssistant: {
            name: 'changeAssistant',
            show: false,
        },
        repairNote: {
            name: 'repairNote',
            show: false,
        },
        rateOurWork: {
            name: 'rateOurWork',
            show: false,
        },
        npsNotCall: {
            name: 'npsNotCall',
            show: false,
        },
        notCall: {
            name: 'notCall',
            show: false,
        },
        purposeThink: {
            name: 'purposeThink',
            show: false,
        },
        purposeRepairNotNeed: {
            name: 'purposeRepairNotNeed',
            show: false,
        },
        purposeSetMaster: {
            name: 'purposeSetMaster',
            show: false,
        },
        giveDesign: {
            name: 'giveDesign',
            show: false,
        },
        moveSeen: {
            name: 'moveSeen',
            show: false,
        },
        giveProject: {
            name: 'giveProject',
            show: false,
        },
        scheduleCall: {
            name: 'scheduleCall',
            show: false,
        },
        called: {
            name: 'called',
            show: false,
        },
        didntCall: {
            name: 'didntCall',
            show: false,
        },
    },
    errorCustomText: '',
}

// getters
const getters = {

}

const actions = {
    open(context, data) {
        if(data.name && state.items.hasOwnProperty(data.name)) {
            setTimeout(()=>{
                context.commit('open', {name:data.name, request:data.request});
            },10);
        }
    },
    getData(context, data) {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_PATH+'/requests/'+data.id+'/',api.axiosConfig({modalData: data.modalData, name: data.name})).then(response => {
                if(typeof response.data === 'object' && +response.data.error > 0) {
                    api.errorHandler(response.data.error);
                }
                //context.commit('setList', response.data.result);
                resolve(response.data.result);
            }).catch(function (error) {
                resolve();
            });
        });
    }
}

const mutations = {
    close(state, data) {
        if(data.name && state.items.hasOwnProperty(data.name)) {
            state.items[data.name].show = false;
            if(!state.items.measureDetail.show){
                state.curItemData = {};
                document.getElementsByTagName('body')[0].classList.remove("noscroll");
            }
        }
        state.errorCustomText = '';
    },
    open(state, data) {
        if(data.name && state.items.hasOwnProperty(data.name)) {
            if(data.request !== false){
                state.curItemData = data.request;
            }
            state.items[data.name].show = true;
        }
    },
    setErrorCustomText(state, data) {
        state.errorCustomText = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
